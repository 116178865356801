@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

.MuiDrawer-docked .MuiDrawer-paper::-webkit-scrollbar {
  width: 6px;
}
.rtl-6cmau2-MuiTypography-root {
  font-family: '"Almarai", "Helvetica", "Arial", sans-serif' !important;
}
.rtl-qh1700-MuiListItemText-root span {
  font-weight: 400 !important;
  font-size: 0.875rem;
  line-height: 0;
}

.stage-overlay-black {
  background: rgba(34, 43, 69, 0.78) !important;
}

.stage-overlay-red {
  background: rgba(135, 47, 47, 0.78) !important;
}

.stage-overlay-blue {
  background: rgba(35, 105, 202, 0.78) !important;
}

.overlay {
  background-blend-mode: overlay;
  min-height: 100%;
  background-size: cover !important;
}
.stage-overlay-white {
  background: rgba(255, 255, 255, 0.5);
}
.color-orange {
  color: orange;
}

/* input feild error (line-height) */
.rtl-fog2vs-MuiFormLabel-root-MuiInputLabel-root,
.rtl-ivbskr-MuiFormLabel-root-MuiInputLabel-root {
  line-height: 1.2 !important;
}

/* added new for production input feild error (line-height) */
.MuiFormControl-root .MuiInputLabel-root {
  line-height: 1.2 !important;
}

/* some input error */
.MuiInput-root.MuiInput-underline input {
  height: auto !important;
}
.MuiOutlinedInput-root input {
  height: auto !important;
}

@media only screen and (max-width: 1000px) {
  html {
    font-size: 14px !important;
  }
}

@media only screen and (max-width: 600px) {
  html {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 400px) {
  html,
  .rtl-zkpyux-MuiTypography-root {
    font-size: 10px !important;
  }
}

.DropzoneArea-class {
  min-height: 170px !important;
}

.previewGrid-container-class {
  padding: 12px !important;
}

/* .previewGrid-item-class {
}

.previewGrid-image-class {
} */
